import Terravialg from "./terravia.webp";
import Myzalg from "./myza.webp";
import Tanakayulg from "./tanakayulogo.webp";
import Hieralg from "./hiera.webp";
import Eonnalg from "./eonna.webp";
import Zoralg from "./zora.webp";
import Enchantelg from "./enchante.webp";
import Tresorlg from "./tresor.webp";
import Navaparklg from "./navapark.webp";
import Fleekhauzlg from "./fleekhauz.webp";
import Yuthicalg from "./yuthica.webp";
import Aetherlg from "./aether.webp";
import Blizfieldlg from "./blizfield.webp";
import Caeluslg from "./caelus.webp";
import Armontlg from "./armont.png";

const logop = {
  Terravialg,
  Myzalg,
  Tanakayulg,
  Hieralg,
  Enchantelg,
  Eonnalg,
  Zoralg,
  Tresorlg,
  Navaparklg,
  Fleekhauzlg,
  Yuthicalg,
  Aetherlg,
  Blizfieldlg,
  Caeluslg,
  Armontlg,
};

export default logop;
