import fasArmont1 from "./fas-armont (1).webp";
import fasArmont2 from "./fas-armont (2).webp";
import fasArmont3 from "./fas-armont (3).webp";
import fasArmont4 from "./fas-armont (4).webp";
import fasArmont5 from "./fas-armont (5).webp";
import fasArmont6 from "./fas-armont (6).webp";
import fasArmont7 from "./fas-armont (7).webp";
import fasArmont8 from "./fas-armont (8).webp";
import fasArmont9 from "./fas-armont (9).webp";
import fasArmont10 from "./fas-armont (10).webp";
import fasArmont11 from "./fas-armont (11).webp";

const fasarmont = [
  fasArmont1,
  fasArmont2,
  fasArmont3,
  fasArmont4,
  fasArmont5,
  fasArmont6,
  fasArmont7,
  fasArmont8,
  fasArmont9,
  fasArmont10,
  fasArmont11,
];

export default fasarmont;
