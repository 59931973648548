import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "./ScrollToTop/ScrollToTop.jsx";
import Navbar from "./component/navbar/navbar.jsx";
import Terravia from "./pages/detail/terravia/terravia.jsx";
import Lokasi from "./component/lokasi/lokasi.jsx";
import Footer from "./component/footer/footer.jsx";
import Myza from "./pages/detail/myza/myza.jsx";
import Tresor from "./pages/detail/tresor/tresor.jsx";
import Yuthica from "./pages/detail/yuthica/yuthica.jsx";
import Ctapage from "./pages/home/section/ctapage/ctapage.jsx";
import Aether from "./pages/detail/aether/aether.jsx";
import Blizfield from "./pages/detail/blizfield/blizfield.jsx";
import Fleekhauz from "./pages/detail/fleekhauz/fleekhauz.jsx";
import Caelus from "./pages/detail/caelus/caelus.jsx";
import Enchante from "./pages/detail/enchante/enchante.jsx";
import Tanakayu from "./pages/detail/tanakayu/tanakayu.jsx";
import Hiera from "./pages/detail/hiera/hiera.jsx";
import Navapark from "./pages/detail/navapark/navapark.jsx";
import Eonna from "./pages/detail/eonna/eonna.jsx";
import Zora from "./pages/detail/zora/zora.jsx";
import Residentials from "./pages/categori/residentials/residentials.jsx";
import Commercial from "./pages/categori/commercials/commercial.jsx";
import Armont from "./pages/detail/armont/armont.jsx";

const App = () => {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terravia" element={<Terravia />} />
          <Route path="/myza" element={<Myza />} />
          <Route path="/tresor" element={<Tresor />} />
          <Route path="/yuthica" element={<Yuthica />} />
          <Route path="/aether" element={<Aether />} />
          <Route path="/blizfield" element={<Blizfield />} />
          <Route path="/fleekhauz" element={<Fleekhauz />} />
          <Route path="/caelus" element={<Caelus />} />
          <Route path="/enchante" element={<Enchante />} />
          <Route path="/tanakayu" element={<Tanakayu />} />
          <Route path="/hiera/" element={<Hiera />} />
          <Route path="/navapark/" element={<Navapark />} />
          <Route path="/eonna/" element={<Eonna />} />
          <Route path="/zora/" element={<Zora />} />
          <Route path="/armont/" element={<Armont />} />
          <Route path="/residentials" element={<Residentials />} />
          <Route path="/Commercials/" element={<Commercial />} />
        </Routes>
        <Ctapage />
        <Lokasi />
        <Footer />
      </Router>
      <a
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6281319499502&text=Halo%20Reza,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketingbsdcity.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
    </div>
  );
};

export default App;
