import imgArmont91 from "./armontt9 (1).webp";
import imgArmont92 from "./armontt9 (2).webp";
import imgArmont93 from "./armontt9 (3).webp";
import imgArmont94 from "./armontt9 (4).webp";
import imgArmont95 from "./armontt9 (5).webp";

const imgArmont9 = [
  imgArmont91,
  imgArmont92,
  imgArmont93,
  imgArmont94,
  imgArmont95,
];

export default imgArmont9;
