import imgArmont81 from "./armontt8 (1).webp";
import imgArmont82 from "./armontt8 (2).webp";
import imgArmont83 from "./armontt8 (3).webp";
import imgArmont84 from "./armontt8 (4).webp";
import imgArmont85 from "./armontt8 (4).webp";

const imgArmont8 = [
  imgArmont81,
  imgArmont82,
  imgArmont83,
  imgArmont84,
  imgArmont85,
];

export default imgArmont8;
