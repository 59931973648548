import imgArmont101 from "./armontt10 (1).webp";
import imgArmont102 from "./armontt10 (2).webp";
import imgArmont103 from "./armontt10 (3).webp";
import imgArmont104 from "./armontt10 (4).webp";
import imgArmont105 from "./armontt10 (5).webp";
import imgArmont106 from "./armontt10 (6).webp";

const imgArmont10 = [
  imgArmont101,
  imgArmont102,
  imgArmont103,
  imgArmont104,
  imgArmont105,
  imgArmont106,
];

export default imgArmont10;
